import React, { useState } from "react";

import HeaderComponent from "./../../components/Header/";
import FooterComponent from "./../../components/Footer/";

import SearchPropertySection from "./SearchPropertySection";
import NewcomersPropertiesSection from "./NewcomersPropertiesSection";
import PropertyAnnouncementSection from "./PropertyAnnouncementSection";
import AdvantagesSection from "./AdvantagesSection";
import MapSection from "./MapSection";

import { Container, Popup, PopupText } from "./styles";
import { FiX } from "react-icons/fi";

export default function Home() {
  const [showPopup, setShowPopup] = useState(true);

  return (
    <>
      <HeaderComponent showAboutUs hasBorder />

      <Container>
        <Popup className={showPopup ? undefined : "hidden"}>
          <PopupText>
            Nosso número para ligações é 4003-7654. Para falar conosco pelo
            WhatsApp, envie uma mensagem para (85) 99742-4051.
            Estamos à disposição!
          </PopupText>
          <FiX onClick={() => setShowPopup(false)} />
        </Popup>

        <SearchPropertySection />
        <NewcomersPropertiesSection />
        <PropertyAnnouncementSection />
        <AdvantagesSection />
        <MapSection />
      </Container>

      <FooterComponent />
    </>
  );
}
