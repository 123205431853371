import styled from "styled-components";

import { MAX_WIDTH_PAGE } from "../../styles";

// CONTAINER

export const Container = styled.div`
  max-width: ${MAX_WIDTH_PAGE};
  margin: 0 auto;
  padding: 0;
  position: relative;
`;

export const Popup = styled.div`
 max-width: ${MAX_WIDTH_PAGE};
  top: 0;
  left: 0;
  right: 0;
  background-color: lightyellow;
  display: flex;
  justify-content: space-around;
  align-items: center;
  animation: fadeIn 500ms forwards;

  svg {
    cursor: pointer;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* Fade-out animation */
  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-20px);
    }
  }

  &.hidden {
    animation: fadeOut 500ms forwards;
  }
`;
export const PopupText = styled.p`
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  @media (max-width: 767px) {
    max-width: 85%;
    font-size: ${12 / 16}rem;
  }

  @media (max-width: 1080px) {
    max-width: 90%;
    font-size: ${14 / 16}rem;
  }
`;
